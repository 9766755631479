
const cart = require('../cart/cart');
const { saveUrlInSessionStorage } = require('../../js/redirectUrl');

let updateMiniCart = true;


/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessageAndRemoveFromCart(data) {
    $.spinner().stop();
    const status = data.success ? 'alert-success' : 'alert-danger';

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append('<div class="add-to-wishlist-messages "></div>');
    }
    $('.add-to-wishlist-messages')
        .append(`<div class="add-to-wishlist-alert text-center ${status}">${data.msg}</div>`);

    setTimeout(() => {
        $('.add-to-wishlist-messages').remove();
    }, 1500);
    const $targetElement = $(`a[data-pid="${data.pid}"]`).closest('.product-info').find('.remove-product');
    const itemToMove = {
        actionUrl: $targetElement.data('action'),
        productID: $targetElement.data('pid'),
        productName: $targetElement.data('name'),
        uuid: $targetElement.data('uuid'),
    };
    $('body').trigger('afterRemoveFromCart', itemToMove);
    setTimeout(() => {
        $('.cart.cart-page #removeProductModal').modal();
    }, 2000);
}
function displayMessageWishlist(data) {
    $.spinner().stop();
    const status = data.success ? 'alert-success' : 'alert-danger';

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append('<div class="add-to-wishlist-messages "></div>');
    }
    $('.add-to-wishlist-messages')
        .append(`<div class="add-to-wishlist-alert text-center ${status}">${data.msg}</div>`);


    setTimeout(() => {
        $('.add-to-wishlist-messages').remove();
    }, 1500);
    const $targetElement = $(`a[data-pid="${data.pid}"]`).closest('.product-info').find('.remove-product');
    const itemToMove = {
        actionUrl: $targetElement.data('action'),
        productID: $targetElement.data('pid'),
        productName: $targetElement.data('name'),
        uuid: $targetElement.data('uuid'),
    };
}
/**
 * move items from Cart to wishlist
 * returns json object with success or error message
 */
function moveToWishlist() {
    $('body').on('click', '.product-move .move', function (e) {
        e.preventDefault();
        const url = $(this).attr('href');
        const pid = $(this).data('pid');
        let optionId = $(this).closest('.product-info').find('.lineItem-options-values').data('option-id');
        let optionVal = $(this).closest('.product-info').find('.lineItem-options-values').data('value-id');
        optionId = optionId || null;
        optionVal = optionVal || null;
        if (!url || !pid) {
            return;
        }

        $.spinner().start();
        $.ajax({
            url,
            type: 'post',
            dataType: 'json',
            data: {
                pid,
                optionId,
                optionVal,
            },
            success(data) {
                displayMessageWishlist(data);
            },
            error(err) {
                displayMessageWishlist(err);
            },
        });
    });
}

$(document).on('click', '#botonPago', function(event) {
    var checkoutUrl = $(this).attr('href');
    saveUrlInSessionStorage(checkoutUrl);
})

module.exports = function () {
    cart();
    moveToWishlist();
    $('.minicart').on('count:update', (event, data) => {
        if (data && $.isNumeric(data.quantityTotal)) {
            $('.minicart .minicart-quantity .text').text(data.quantityTotal);

            //.minicart-subtotal actualization
                var subTotalIncoming = data.totals.subTotal;
                var subTotalString = String(subTotalIncoming);
                var cleanedSubtotal = subTotalString
                    .replace(/[^0-9.,]/g, '')
                    .replace(',', '.');
                var subTotalValue = parseFloat(cleanedSubtotal);
                var formattedSubtotal = '';
                if (!isNaN(subTotalValue)) {
                    formattedSubtotal = new Intl.NumberFormat('es-ES', {
                        style: 'currency',
                        currency: 'EUR',
                    }).format(subTotalValue);
                    formattedSubtotal = formattedSubtotal.replace(/\s/g, '');
                } else {
                    console.error("El subtotal no es un número válido:", cleanedSubtotal);
                }
            $('.minicart .minicart-subtotal .text').text(formattedSubtotal);
            
            $('.minicart .minicart-link').attr({
                'aria-label': data.minicartCountOfItems,
                title: data.minicartCountOfItems,
            });
            
            var prodsQuantity = 0;
            if (data && data.items) {
                const items = data.items;

                for (var i = 0; i < items.length; i++) {
                    if (items[i].unitData && items[i].unitData.unit === 'kg') {
                        prodsQuantity++;
                    } else {
                        prodsQuantity += items[i].quantity;
                    }
                }
                $('.minicart .minicart-quantity .text').text(prodsQuantity);
                $('.minicart .minicart-subtotal .text').text(formattedSubtotal);
                $('.minicart .minicart-link').attr({
                    'aria-label': prodsQuantity,
                    title: prodsQuantity,
                });
            } else if(data && data.cart && data.cart.items) {
                const items = data.cart.items;

                for (var i = 0; i < items.length; i++) {
                    if (items[i].unitData && items[i].unitData.unit === 'kg') {
                        prodsQuantity++;
                    } else {
                        prodsQuantity += items[i].quantity;
                    }
                }
                $('.minicart .minicart-quantity .text').text(prodsQuantity);
                $('.minicart .minicart-subtotal .text').text(formattedSubtotal);
                $('.minicart .minicart-link').attr({
                    'aria-label': prodsQuantity,
                    title: prodsQuantity,
                });
            }

            // only needed when new products are added, on plp qty increase this isn't needed
            if (data && data.cart && data.cart.items) {
                const itemsquantities = [];
                const { items } = data.cart;

                items.forEach((item) => {
                    itemsquantities.push({ id: item.id, qty: item.quantity });
                });
                $('.cart-json').data('cart', { itemsquantities });
            }
        }
    });

    $('.minicart, .pd-mini-cart-logo').on('mouseenter focusin touchstart', () => {
        if ($('.search:visible').length === 0) {
            return;
        }
        const url = $('.minicart').data('action-url');
        const count = parseInt($('.minicart .minicart-quantity .text').text(), 10);

        if (count !== 0 && $('.minicart .popover.show').length === 0) {
            if (!updateMiniCart) {
                $('.minicart .popover').addClass('show');
                return;
            }

            $('.minicart .popover').addClass('show');
            $('.minicart .popover').spinner().start();
            $.get(url, (data) => {
                $('.minicart .popover').empty();
                $('.minicart .popover').append(data);
                updateMiniCart = false;
                $.spinner().stop();
            });
        }
    });
    $('body').on('touchstart click', (e) => {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
        }
    });
    $('.minicart, .pd-mini-cart-logo').on('mouseleave focusout', (event) => {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0) ||
            (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity')) ||
            $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
        }
    });
    $('body').on('product:afterAddToCart', () => {
        updateMiniCart = true;
    });
    $('body').on('cart:update', () => {
        updateMiniCart = true;
    });
};
