window.jQuery = require('jquery');
window.$ = require('jquery');
const processInclude = require('base/util');

$(document).ready(() => {
    processInclude(require('base_store/components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base_store/components/consentTracking'));
    processInclude(require('base/components/footer'));
    // This include is also included in the app_custom_masymas cartridge. If you don't include app_custom_masymas uncomment this
    // processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('base/components/search'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('sunnie_foods/components/scheduledorder'));
    // This include is also included in the plugin_add_online_shipping cartridge. If you don't include plugin_add_online_shipping uncomment this
    // processInclude(require('base_store/storeLocator/selectStoreModal'));
    processInclude(require('base_store/storeLocator/storePopup'));
    processInclude(require('sunnie_foods/components/sunnieFoodsHeader'));
});

require('./thirdParty/bootstrap');
require('base_store/components/spinner');
