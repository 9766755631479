/**
 * showSelectStoreModal
 */
function showSelectStoreModal() {
    $('#selectStoreModal').modal({ keyboard: false });
    $('#btn-noStore').click(() => {
        const url = $('#btn-noStore').data('action');

        $.ajax({
            url,
        }).done(() => {
            // redirect to homepage
            const redirectUrl = $('#btn-noStore').data('redirecturl');
            window.location.href = redirectUrl;
        });
    });
}

/**
 * drawSelectStoreModal
 * Modal Dialog to let shopper select a store
 */
function drawSelectStoreModal(checkout) {
    var url = $('.headerselectstore').data('selectstoremodalurl');
    if (checkout) {
        url = $('.change-shipping-method').data('selectstoremodalurl');
    }

    $.spinner().start();
    $.ajax({
        url,
        type: 'get',
        dataType: 'json',
        success(data) {
            $('body').append(data.selectStoreModal);
            $.spinner().stop();
            if (!($('.consented').length === 0 && $('.tracking-consent').hasClass('api-true'))) {
                showSelectStoreModal();
            }
        },
    });
}

/**
 * findStore
 * Find store by postalcode
 */
 function findStoreByPostalCode() {
    var url = $('#btn-findByPostalCode').data('action');
    var data = {
        postalcode: $('#select-store-postalcode').val()
    };
    $.spinner().start();
    $.ajax({
        url,
        type: 'get',
        data: data,
        dataType: 'json',
        success(data) {
            if (data.success) {
                $('.no-store-found').addClass('d-none');
                $.spinner().stop();
                var urlLink = $('.select-store-popup-link').attr('href');
                $('.select-store-popup-link').attr('href', urlLink + '?id=' + data.id);
                $('.find-postal-code').removeClass('d-none');
                $('.data-store').html(data.name + ": " + data.address);
                $('.select-store-popup').removeClass('d-none');
            } else {
                $('.no-store-found').removeClass('d-none');
                $('.select-store-popup').addClass('d-none');
            }
        },
    });
}

module.exports = {
    init() {
        if ((($('.search-results').length === 1) || ($('.product-detail').length === 1) || ($('#homepage').length === 1)) && ($('.headerselectstore').length !== 0) && $('.headerselectstore').data('showstoreselectormodal')) {
            // drawSelectStoreModal();
        }
    },
    showSelectStoreModal(show) {
        //if (show) {
        //    showSelectStoreModal();
        //}
    },
    findStoreByPostalcode: function () {
        $('body').on('click', '#btn-findStoreByPostalCode', function (e) {
            $('.find-postal-code').removeClass('d-none');
        });

        $('body').on('click', '#btn-findByPostalCode', function (e) {
            findStoreByPostalCode();
        });

        $('body').on('keypress', '#select-store-postalcode', function (e) {
            if (e.which === 13) { //ref ASCII para tecla enter
                e.preventDefault();
                findStoreByPostalCode();
            }
        });
    },
    showSelectStoreModalCheckout: function () {
        //$('body').on('click', '.change-shipping-method', function (e) {
        //    drawSelectStoreModal(true);
        //});
    }
};
