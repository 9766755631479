'use strict';

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        $(this).find('input, select').each(function () {
            if (!this.validity.valid) {
                $(this).trigger('invalid', this.validity);
            }
        });
    }
    return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    $(form).find('.form-control.is-invalid').removeClass('is-invalid');
    $(form).find('.invalid-feedback').each(function () {
        $(this).text('');
        $(this).css('display', 'none');
    });
}

/**
 * Remove all validation.
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearErrorMessages(element) {
    element.classList.remove('is-invalid');
}

/**
 * Verify the email inputs equality.
 * @returns {validationMessage}
 */
function validateEmailEquality() {
    var email = document.getElementById('registration-form-email');
    var emailDivError = document.getElementById('form-email-error');
    var emailConfirm = document.getElementById('registration-form-email-confirm');
    var emailConfirmDivError = document.getElementById('form-email-confirm-error');
    var validationMessage = '';

    clearErrorMessages(email);
    clearErrorMessages(emailConfirm);
    emailDivError.textContent = '';
    emailConfirmDivError.textContent = '';

    if (email.value !== emailConfirm.value) {
        emailDivError.style.display = 'block'; // Ensure the error message is visible
        emailConfirmDivError.style.display = 'block'; // Ensure the error message is visible
        validationMessage = 'Los correos electrónicos no coinciden.';
        email.classList.add('is-invalid');
        emailConfirm.classList.add('is-invalid');
        emailDivError.innerText = validationMessage;
        emailConfirmDivError.innerText = validationMessage;
    }
    return validationMessage;
}


/**
 * Verify the password inputs equality.
 * @returns {validationMessage}
 */
function validatePasswordEquality() {
    var password = document.getElementById('registration-form-password');
    var passwordDivError = document.getElementById('form-password-error');
    var passwordConfirm = document.getElementById('registration-form-password-confirm');
    var passwordConfirmDivError = document.getElementById('form-password-confirm-error');
    var validationMessage = '';

    clearErrorMessages(password);
    clearErrorMessages(passwordConfirm);
    passwordDivError.textContent = '';
    passwordConfirmDivError.textContent = '';

    if (password.value !== passwordConfirm.value) {
        passwordDivError.style.display = 'block'; // Ensure the error message is visible
        passwordConfirmDivError.style.display = 'block'; // Ensure the error message is visible
        validationMessage = 'Las contraseñas no coinciden.';
        password.classList.add('is-invalid');
        passwordConfirm.classList.add('is-invalid');
        passwordDivError.innerText = validationMessage;
        passwordConfirmDivError.innerText = validationMessage;
    }
    return validationMessage;
}

module.exports = {
    invalid: function () {
        $('form input, form select').on('invalid', function (e) {
            e.preventDefault();
            this.setCustomValidity('');
            var invalidFeedback = $(this).parents('.form-group').find('.invalid-feedback');
            invalidFeedback.css('display', 'none');
            if (!this.validity.valid) {
                var validationMessage = this.validationMessage;
                $(this).addClass('is-invalid');
                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                invalidFeedback.text(validationMessage);
                invalidFeedback.css('display', 'block');
            }
        });
    },

    submit: function () {
        $('form').on('submit', function (e) {
            var registerTab = document.getElementById('register-tab');
            // var allInvalidFeedbackArray = document.querySelectorAll('.invalid-feedback');
            if (registerTab && registerTab.classList.contains('active')) {
                // Verification emails inputs and passwords inputs equality
                var emailValidationMessage = validateEmailEquality();
                var passwordValidationMessage = validatePasswordEquality();
                // Si alguna de las funciones de validación retorna un mensaje de error, cancela el envío del formulario de registro
                if (emailValidationMessage || passwordValidationMessage) {
                    e.preventDefault();
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                } else {
                    // Si no hay errores, permite el envío del formulario de registro
                    clearForm(this);
                    return validateForm.call(this, e);
                }
            } else {
                // allInvalidFeedbackArray.forEach(function (element) {
                clearForm(this);
                return validateForm.call(this, e);
            }
        });
    },

    buttonClick: function () {
        $('form button[type="submit"], form input[type="submit"]').on('click', function () {
            // clear all errors when trying to submit the form
            clearForm($(this).parents('form'));
        });
    },

    functions: {
        validateForm: function (form, event) {
            validateForm.call($(form), event || null);
        },
        clearForm: clearForm
    }
};